::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #f6f6f6;
}

::-webkit-scrollbar-thumb {
  min-height: 48px;
  border-radius: 4px;
  background: #c3c3c3;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

.scrollable-bottom-shadow {
  display: none;
  position: absolute;
  height: 20px;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(30, 89, 168, 0.12) 0%, rgba(0, 0, 0, 0) 100%);
}

.iziToast:after {
  box-shadow: none !important;
}
